/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap"); */
@import url("tldraw/tldraw.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Rajdhani', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark {
  color: #4D4D4D !important;
}

.primary{
  color: #30C5FF !important;
}
