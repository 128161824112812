
.canvas-container {
    position: relative;
    height: calc(100vh - 150px); /* Adjust this value as needed */
  }
  
  .canvas-toolbar {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .canvas {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #f0f0f0;
  }
  .react-flow__node-default {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    width: 100px;
  }
  
  .react-flow__node-default img {
    width: 100px;
    height: auto;
    display: block;
    padding: 0;
    margin: 0;
  }

  .custom-button {
    background-color: #EBEEED; /* Adjust this color to match the blue color */
    color: rgb(53, 53, 53); /* White text color */
    border: none; /* Remove border */
    border-radius: 10px; /* Rounded corners */
    padding: 10px 10px; /* Padding for button size */
    font-size: 11px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for 3D effect */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .custom-button:hover {
    background-color: #a7a7a7; /* Darker blue on hover */
  }

  .modal-backdrop {
    position: fixed; /* Positioned relative to the viewport */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    display: flex;
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    z-index: 1050; /* Make sure this is above all other content */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: auto; /* Adjust width as necessary */
    z-index: 1051; /* Ensure content is above backdrop */
}

.modal-button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.modal-button:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.confirm-button {
  background-color: #4CAF50; /* Green */
  color: white;
}

.confirm-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336; /* Red */
  color: white;
}

.cancel-button:hover {
  background-color: #d73833;
}
.modal-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  /* width: 100%; */
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}



.post-it-node {
  background-color: #c5e1a5;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.post-it-node:focus, .post-it-node:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
}

.react-flow__minimap {
  background-color: #333; /* Dark background for better contrast */
  position: absolute;
  bottom: 80px; /* Adjust the bottom position */
  right: 10px; /* Adjust the right position */
  z-index: 1001; /* Ensure it's above other elements */
  width: 150px; /* Adjust the size */
  height: 100px; /* Adjust the size */
}

.react-flow__minimap-node {
  border-color: #fff; /* White borders for nodes */
  background-color: #aaa; /* Lighter node fill for visibility */
}

.react-flow__minimap-mask {
  background-color: rgba(51, 51, 51, 0.7); /* Semi-transparent mask for contrast */
}


.react-flow__attribution {
  display: none !important;
}

/* Position the Zoom Controls */
.react-flow__controls {
  position: absolute;
  top: 10px; /* Adjust the top position */
  left: 10px; /* Adjust the left position */
  z-index: 1001; /* Ensure it's above other elements */
}

.session-info{
  font-size: x-small;
  margin-top: 5px;
}


.footer_small {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #f1f1f1;
  text-align: left;
  padding-left: 10px;
  line-height: 20px;
  font-size: 12px;
  border-top: 1px solid #ccc;
  z-index: 1000; /* Ensure it's on top of other elements */
}