.card-container {
    display: flex;
    justify-content: space-between;
  }
  
  .card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }